import { Link } from "react-router-dom";
import { formatDate } from "../../../shared/utility";
import TextWithLabel from "../../controls/TextWithLabel";
import ManufactureRecipeItemsTable from "../production/components/ManufactureRecipeItemsTable";
import ManufactureIngredientsTable from "../production/components/ManufactureIngredientsTable";

export default function TraceInventoryArea(props) {
  const { product, openReceivingItemInformation } = props;

  return (
    <div className="col-span-2">
      <h3 className="text-lg mb-2">Inventory</h3>
      <div className="overflow-hidden card-custom p-4 flex">
        <div className="grid grid-cols-4 gap-4 mt-2 flex-grow">
          <TextWithLabel label="Inventory" text={0} />
          <TextWithLabel label="Sold" text={99} />
        </div>
      </div>
    </div>
  );
}
