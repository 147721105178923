import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import CustomerDetailsForm from "../../../components/forms/setup/customer/CustomerDetailsForm";
import { BannerNotificationType } from "../../../components/SimpleBanner";
import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";
import ImportMediaCsvDropzone from "../../../components/importMedia/ImportMediaCsvDropzone";

export function CustomersViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState({
    data: [],
  });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id");

  const getCustomers = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`customers`, payload);
    setLoading(false);
    if (callResult.success) {
      setCustomers(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createCustomer = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall("createCustomer", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Customer added",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getCustomers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateCustomer = async function (customer, values, index) {
    let callResult = await makePutApiCall(`customers/${customer.id}`, values);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Customer updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getCustomers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddCustomerSlideOver = () => {
    setSlideOver({
      childComponent: (
        <CustomerDetailsForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createCustomer}
        />
      ),
      open: true,
    });
  };

  const viewCustomerDetails = async function (customer, index) {
    setSlideOver({
      childComponent: (
        <CustomerDetailsForm
          customer={customer}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateCustomer}
        />
      ),
      open: true,
    });
  };

  const deleteCustomer = async function (id, index) {
    setLoading(true);

    let callResult = await makePostApiCall(`deleteCustomer/${id}`, {});
    setLoading(false);
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getCustomers(null, 0);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteCustomer = function (customer, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteCustomer(customer.id, index)}
          message={`Are you sure you want to delete ${customer.name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const uploadCustomersCsv = async function (uploadedFile) {
    const { name, value } = uploadedFile.target;
    const payload = { [name]: value };
    let callResult = await makePostApiCall(
      "uploadCustomersCsv",
      payload,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        open: true,
        message: null,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getCustomers("", 0);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openImportCustomers = () => {
    setEmptyModelOpen({
      childComponent: (
        <ImportMediaCsvDropzone
          fileName="customersCsv"
          actionCall={uploadCustomersCsv}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  return {
    askDeleteCustomer,
    emptyModal,
    setEmptyModelOpen,
    createCustomer,
    getCustomers,
    loading,
    customers,
    openAddCustomerSlideOver,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    updateCustomer,
    viewCustomerDetails,
    openImportCustomers,
  };
}
