import { useEffect, useRef, useState } from "react";
import { ManageOrdersViewModel } from "../../viewmodels/orders/ManageOrdersViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import {
  formatDateWithTime,
  orderStatusOptions,
  withRouter,
} from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SlideOver from "../../components/slideOver/SlideOver";
import SimpleBanner from "../../components/SimpleBanner";
import { Link } from "react-router-dom";

import { Paginator } from "primereact/paginator";
import Pills, { PillType } from "../../components/controls/Pills";
import FilterDropdown from "../../components/filterDropdown/FilterDropdown";

function ManageOrders(props) {
  const { logout } = props;
  const {
    filterComponents,
    getCustomersForDropdown,
    getOrders,
    loading,
    orders,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    askDeleteOrder,
    filters,
    resetFilters,
    setShowFilters,
    showFilters,
  } = ManageOrdersViewModel(logout);

  var currentPage = 1;
  useEffect(() => {
    getCustomersForDropdown();
    getOrders(null, currentPage, filters);
  }, []);

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(20);

  const searchRef = useRef();

  const handleSearch = () => {
    getOrders(searchRef.current.value, currentPage, filters);
  };

  const handlePagination = (event) => {
    currentPage = event.page + 1;
    setFirst(event.first);
    setRows(event.rows);
    getOrders(searchRef.current.value, currentPage, filters);
  };

  const convertStatus = (status) => {
    const statusOption = orderStatusOptions.filter((el) => el.value === status);

    return statusOption.length === 1
      ? { text: statusOption[0].label, type: statusOption[0].type }
      : { text: orderStatusOptions[0].label, type: orderStatusOptions[0].type };
  };

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center px-4 py-5 sm:px-4 card-custom">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Orders
              </h1>
              <div className="mt-5 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchProduct"
                    id="searchProduct"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by name"
                    ref={searchRef}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSearch() : null
                    }
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-1 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>

                <FilterDropdown
                  actionCall={() =>
                    getOrders(searchRef.current.value, currentPage, filters)
                  }
                  filterComponents={filterComponents}
                  resetFilters={() => {
                    resetFilters();
                  }}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />

                <button
                  onClick={() => (window.location = "/create-order")}
                  className="ml-2 rounded-md bg-main-purple px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create Order
                </button>
              </div>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden card-custom">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-white">
                        <tr>
                          <th
                            scope="col"
                            className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            PO #
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Customer
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {orders.data.length > 0 ? (
                          orders.data.map((order) => (
                            <tr key={order.id}>
                              <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {order.po_number}
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
                                {order.customer}
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
                                {formatDateWithTime(order.created_at)}
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
                                <Pills content={convertStatus(order.status)} />
                              </td>
                              <td className="relative whitespace-nowrap py-1.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link
                                  className="rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  to={`/orders/${order.id}`}
                                >
                                  Edit
                                </Link>
                                <button
                                  onClick={() => {
                                    askDeleteOrder(order);
                                  }}
                                  className="ml-2 rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
                              colSpan={4}
                            >
                              No Orders
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="w-full">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={orders.total}
                  onPageChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageOrders)
);
