import { useEffect, useRef, useState } from "react";
import { InventoryRawMaterialViewModel } from "../../../viewmodels/inventory/rawMaterial/InventoryRawMaterialViewModel";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { withRouter } from "../../../shared/utility";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { InputText } from "primereact/inputtext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import SelectMenu from "../../../components/controls/SelectMenu";

function InventoryRawMaterialScreen(props) {
  const { logout } = props;
  const {
    getInStockRawMaterial,
    loading,
    products,
    onUpdateReceivingItemStock,
    getProductTypes,
    productTypeOptions,
  } = InventoryRawMaterialViewModel(logout);

  var currentPage = 1;
  const [filter, setFilter] = useState(-1);
  useEffect(() => {
    getInStockRawMaterial(null, currentPage, filter);
    getProductTypes();
  }, []);

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(20);

  const searchRef = useRef();

  const handleSearch = () => {
    getInStockRawMaterial(searchRef.current.value, currentPage, filter);
  };

  const handlePagination = (event) => {
    currentPage = event.page + 1;
    setFirst(event.first);
    setRows(event.rows);
    getInStockRawMaterial(searchRef.current.value, currentPage, filter);
  };

  const [expandedRows, setExpandedRows] = useState(null);

  const allowExpansion = (rowData) => {
    return true;
  };

  const parentTotalCostTemplate = (data) => {
    return <div>${data.total_cost}</div>;
  };

  const subItemUnitCostTemplate = (data) => {
    return <div>${data.unit_cost}</div>;
  };

  const subItemTotalCostTemplate = (data) => {
    return <div>${data.total_cost}</div>;
  };

  const headerTemplate = (data) => {
    return (
      <div className="py-2 bg-gray-100 border-1-bottom-gray px-2 text-sm">
        <span className="font-bold">{data.category_name}</span>
      </div>
    );
  };

  const onCellEditComplete = (e) => {
    let { rowData, rowIndex, newValue, field, originalEvent: event } = e;

    switch (field) {
      default:
        if (newValue.trim().length > 0) {
          const oldItemCost = rowData.total_cost;
          const newItemCost = (
            parseFloat(newValue) * parseFloat(rowData.unit_cost)
          ).toFixed(2);

          rowData[field] = parseFloat(newValue).toFixed(2);
          rowData["total_cost"] = newItemCost;
          onUpdateReceivingItemStock(
            rowData.id,
            parseFloat(newValue),
            rowData.old_in_stock,
            rowData.parent_index,
            oldItemCost,
            newItemCost
          );
        } else {
          event.preventDefault();
        }
        break;
    }
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
      />
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        {/* <h5>Orders for {data.name}</h5> */}
        <DataTable
          value={data.subItems}
          tableClassName="min-w-full bg-white divide-y divide-gray-300 raw-inventory-table-child"
          cellClassName="py-2 bg-gray-100 border-1-bottom-gray px-2 text-sm"
          editMode="cell"
        >
          <Column field="supplier_name" header="Supplier Name" />
          <Column field="invoice_number" header="Invoice Number" />
          <Column field="product_name" header="Product Name" />
          <Column header="Unit Cost" body={subItemUnitCostTemplate} />
          <Column
            field="in_stock"
            header="Total Unit"
            editor={(options) => textEditor(options)}
            onCellEditComplete={onCellEditComplete}
          />
          <Column header="Total Cost" body={subItemTotalCostTemplate} />
        </DataTable>
      </div>
    );
  };

  return (
    <div>
      <div className="mt-2 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex bg-white px-4 py-5 border-b border-gray-200 sm:px-4 rounded-xl">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              In stock Raw Material
            </h1>
            <div className="mt-5 flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10 w-[350px]">
                <input
                  type="search"
                  name="searchReceivingInvoice"
                  id="searchReceivingInvoice"
                  className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                  placeholder="Search by BOM name or plu"
                  ref={searchRef}
                  onKeyDown={(e) => (e.key === "Enter" ? handleSearch() : null)}
                />
              </div>
              <button
                type="button"
                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                onClick={() => handleSearch()}
              >
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </button>
              <SelectMenu
                handleInputChange={(e) => {
                  const newFilter = e.target.value.value;
                  getInStockRawMaterial(
                    searchRef.current.value,
                    currentPage,
                    newFilter
                  );
                  setFilter(newFilter);
                }}
                parentClass="ml-5 w-56"
                placeholder="Product type"
                name="product_type"
                options={productTypeOptions}
                showLabel={false}
              />
            </div>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {/* <button
                type="button"
                className="block rounded-md bg-main-purple px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add user
              </button> */}
            <h1 className="text-base font-bold leading-6 text-red-500">
              Total Raw In Stock{" "}
              <span className="text-green-700">
                ${products.totalStockDollarAmount}
              </span>
            </h1>
            <h1 className="text-base font-bold leading-6">
              Page Total Raw In Stock{" "}
              <span className="text-green-700">
                ${products.totalPageStockDollarAmount}
              </span>
            </h1>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {loading ? (
                <div>
                  <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                    <div className="animate-pulse flex space-x-4">Loading</div>
                  </div>
                </div>
              ) : (
                <div className="overflow-hidden card-custom bg-white">
                  <DataTable
                    value={products.data}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => {
                      setExpandedRows(e.data);
                    }}
                    rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="id"
                    tableClassName="min-w-full bg-white divide-y divide-gray-300 text-sm raw-inventory-table-parent"
                    cellClassName="p-2 bg-white border-1-bottom-gray text-sm"
                    rowGroupMode="subheader"
                    groupRowsBy="category_name"
                    rowGroupHeaderTemplate={headerTemplate}
                    scrollHeight="100%"
                  >
                    <Column field="bom_plu" header="Bom Plu" />
                    <Column field="bom_name" header="Bom Name" />
                    <Column
                      field="total_stock"
                      header="Amount in stock"
                      style={{ textAlign: "right" }}
                    />
                    <Column
                      field="unit_of_measure"
                      header="Unit of Measure"
                      style={{ textAlign: "right" }}
                    />
                    <Column
                      header="Total Cost"
                      body={parentTotalCostTemplate}
                      style={{ textAlign: "right" }}
                    />
                    {/* <Column header="" body={massItemActionTemplate} /> */}
                    <Column
                      expander={allowExpansion}
                      style={{ width: "5rem" }}
                    />
                  </DataTable>
                </div>
              )}
            </div>
            <div className="w-full">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={products.total}
                // rowsPerPageOptions={[10, 20, 30]}
                onPageChange={handlePagination}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryRawMaterialScreen)
);
