import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";

import { config } from "../../constants";
import NitrateAndPhosphateCalculator from "../../components/forms/production/components/NitrateAndPhosphateCalculator";

export function ManufacturedProductViewModel(logout, productId) {
  const [loading, setLoading] = useState(true);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [certifiedByOptions, setCertifiedByOptions] = useState([]);
  const [claimsOptions, setClaimsOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [printLabelDetails, setPrintLabelDetails] = useState({
    production_date: null,
    product_plu: null,
    product_name: null,
    product_description: null,
    product_weight: null,
    product_lot: null,
    supervisor: null,
    product_allergens: null,
    barcodeHtml: null,
    barcode: null,
  });
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState([]);
  const [printType, setPrintType] = useState(0);
  const [manufacturedProduct, setManufacturedProduct] = useState({
    ingredients: [],
  });

  const getProductReportById = async function (productId) {
    const payload = {
      product_id: productId,
    };
    let callResult = await makePostApiCall(
      `manufacturedProducts/id/${productId}`,
      payload
    );

    if (callResult.success) {
      const manufacturedProduct = callResult.data;
      setManufacturedProduct(manufacturedProduct);

      if (manufacturedProduct.recipe_id > 0) {
        setSelectedRecipe({
          recipe: manufacturedProduct.selectedRecipe.recipe,
          recipeItems: manufacturedProduct.selectedRecipe.recipeItems,
        });
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  const getCertifiedByOptionsForDropdown = async function () {
    let callResult = await makePostApiCall(`certifiedByOptionsForDropdown`);

    if (callResult.success) {
      setCertifiedByOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getClaimsForDropdown = async function () {
    let callResult = await makePostApiCall(`claimsForDropdown`);

    if (callResult.success) {
      setClaimsOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getAssemblyItemReceivingByBarcode = async function (search, page) {
    const payload = {
      manufacture: true,
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`receivingInvoiceIndex`, payload);

    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getProductsList = async function (search) {
    const payload = {
      page: 1,
      search: search,
    };
    let callResult = await makePostApiCall(`products`, payload);

    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getReceivingAssemblyItemInStockByName = async function (search) {
    let callResult = await makePostApiCall(
      `getReceivingAssemblyItemInStockByName`,
      {
        search: search,
      }
    );

    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateManufacturedProduct = async function (productValues) {
    let updateProductResult = await makePostApiCall(
      "updateManufacturedProduct",
      productValues
    );
    let recipeIngredientsResult = {};
    if (updateProductResult.success) {
      if (selectedRecipe.recipe) {
        recipeIngredientsResult = await makePostApiCall(
          "saveOrUpdateManufactureIngredients",
          {
            ...selectedRecipe,
            manufactured_product_id: productValues.manufactured_product_id,
          }
        );
        if (recipeIngredientsResult.success) {
          let getItemsToRefreshRecipe = await getProductReportById(
            productValues.manufactured_product_id
          );
        } else {
          if (recipeIngredientsResult.errorStatus === 401) {
            logout();
          }
        }
      }
      setManufacturedProduct({
        ...productValues,
        ...recipeIngredientsResult.data,
      });
      return updateProductResult;
    } else {
      if (updateProductResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getManufacturedProductIngredients = async function (
    manufactedProductId
  ) {
    let callResult = await makePostApiCall(
      `getManufacturedProductIngredients/${manufactedProductId}`
    );
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const addIngredientToManufacturedProduct = async function (
    ingredientValues,
    isEdit
  ) {
    let callResult = await makePostApiCall(
      isEdit
        ? "updateManufacturedProductIngredientById/" + ingredientValues.id
        : "addIngredientToManufacturedProduct",
      ingredientValues
    );
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteIngredientFromManufacturedProduct = async function (
    ingredientId
  ) {
    let callResult = await makePostApiCall(
      `deleteIngredientFromManufacturedProduct/${ingredientId}`
    );
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const addManualEntryForManufacturingProduct = async function (productValues) {
    let callResult = await makePostApiCall(
      `addManualEntryToManufacturedProduct`,
      productValues
    );
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getManufacturedProductLabel = async function (manufacturedProductId) {
    let callResult = await makePostApiCall(
      `getManufacturedProductLabel/${manufacturedProductId}`
    );
    if (callResult.success) {
      setPrintType(1);
      setPrintLabelDetails(callResult.data);

      setTimeout(() => {
        window.print();
      }, 1500);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getEmployeesForDropdown = async function (type, department) {
    const payload = { department: department, type: [type] };
    let callResult = await makePostApiCall(`employeesForDropdown`, payload);

    if (callResult.success) {
      if (type === 1) {
        setEmployeeOptions(callResult.data);
      } else {
        setSupervisorOptions(callResult.data);
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchRecipesForDropdown = async function () {
    let callResult = await makePostApiCall(`recipesForDropdown`);

    if (callResult.success) {
      setRecipes(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchRecipeItems = async function (id) {
    let callResult = await makePostApiCall(`fetchRecipeForUse/${id}`);

    if (callResult.success) {
      setSelectedRecipe({
        recipe: callResult.data.recipe,
        recipeItems: [
          ...callResult.data.recipeItems,
          ...callResult.data.packagingItems,
        ],
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const printProductionCostReport = async function () {
    setPrintType(2);

    setTimeout(() => {
      window.print();
    }, 1500);
  };

  const getProductionBatchReport = async function (id) {
    let callResult = await makePostApiCall(`createProductionBatchReport/${id}`);

    if (callResult.success) {
      window.open(callResult.data.location, "_blank").focus();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getLabelCheckReport = async function (id) {
    let callResult = await makePostApiCall(`createLabelCheckReport/${id}`);

    if (callResult.success) {
      window.open(callResult.data.location, "_blank").focus();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getVacuumPackReport = async function (id) {
    let callResult = await makePostApiCall(`createVacuumPackReport/${id}`);

    if (callResult.success) {
      window.open(callResult.data.location, "_blank").focus();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getCookingAndCoolingReport = async function (id) {
    let callResult = await makePostApiCall(
      `createCookingAndCoolingReport/${id}`
    );

    if (callResult.success) {
      window.open(callResult.data.location, "_blank").focus();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getMetalDetectorReport = async function (id) {
    let callResult = await makePostApiCall(`createMetalDetectorReport/${id}`);

    if (callResult.success) {
      window.open(callResult.data.location, "_blank").focus();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onGenerateNitrateAndPhosphateReport = async function (payload) {
    let callResult = await makePostApiCall(`createNitrateAndPhosphateReport`, {
      ...payload,
      product_name: manufacturedProduct.product_name,
    });

    if (callResult.success) {
      window.open(callResult.data.location, "_blank").focus();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openNitrateAndPhosphateCalculator = () => {
    setEmptyModelOpen({
      childComponent: (
        <NitrateAndPhosphateCalculator
          manufacturedProduct={manufacturedProduct}
          actionCall={onGenerateNitrateAndPhosphateReport}
        />
      ),
      open: true,
    });
  };

  return {
    loading,
    addIngredientToManufacturedProduct,
    deleteIngredientFromManufacturedProduct,
    emptyModal,
    getManufacturedProductIngredients,
    getProductReportById,
    manufacturedProduct,
    setEmptyModelOpen,
    showSimpleNotification,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    certifiedByOptions,
    getAssemblyItemReceivingByBarcode,
    getCertifiedByOptionsForDropdown,
    getProductsList,
    updateManufacturedProduct,
    getReceivingAssemblyItemInStockByName,
    addManualEntryForManufacturingProduct,
    getManufacturedProductLabel,
    printLabelDetails,
    employeeOptions,
    getEmployeesForDropdown,
    fetchRecipesForDropdown,
    recipes,
    fetchRecipeItems,
    selectedRecipe,
    setSelectedRecipe,
    showErrorModal,
    printType,
    printProductionCostReport,
    getProductionBatchReport,
    getLabelCheckReport,
    getVacuumPackReport,
    getCookingAndCoolingReport,
    getMetalDetectorReport,
    claimsOptions,
    getClaimsForDropdown,
    supervisorOptions,
    openNitrateAndPhosphateCalculator,
  };
}
