import { classNames, formatDate } from "../../../../shared/utility";

import alert_sign from "../../../../assets/label-assets/alert_sign_cropped.png";

export default function ManufacturedProductLabel(props) {
  const { printLabelDetails } = props;

  return (
    <div className="hidden label-section-to-print">
      <div className="grid grid-cols-2 my-2 ml-5">
        <div>
          <p className="text-barcode-slip">
            <span className="font-bold">Production date:</span>
            {formatDate(printLabelDetails.production_date)}
          </p>
          <p className="text-barcode-slip">
            <span className="font-bold">Product PLU:</span>{" "}
            {printLabelDetails.product_plu}
          </p>
          <p className="text-barcode-slip">
            <span className="font-bold">Product Name:</span>{" "}
            {printLabelDetails.product_name}
          </p>
        </div>
        <div>
          {printLabelDetails.product_allergens ? (
            <div className="float-right mr-2">
              <img
                src={alert_sign}
                alt="ALLERGEN WARNING"
                width="35px"
                height="35px"
              />
            </div>
          ) : null}
        </div>
        <div className="col-span-2">
          <p className="text-barcode-slip">
            <span className="font-bold">Product Description:</span>{" "}
            {printLabelDetails.product_description}
          </p>
        </div>
        <div>
          <p className="text-barcode-slip">
            <span className="font-bold">Production Weight:</span>{" "}
            {printLabelDetails.product_weight}
          </p>
          <p className="text-barcode-slip">
            <span className="font-bold">Production Lot Number:</span>{" "}
            {printLabelDetails.product_lot}
          </p>
          <p className="text-barcode-slip">
            <span className="font-bold">Production Supervisor:</span>{" "}
            {printLabelDetails.supervisor}
          </p>
        </div>
        <div>
          <p className="text-barcode-slip font-bold">
            Allergen:{" "}
            {printLabelDetails.product_allergens
              ? printLabelDetails.product_allergens
              : "None"}
          </p>
          <p></p>
          <p></p>
        </div>
        <div className="col-span-2 mx-5 my-2">
          <img
            src={"data:image/png;base64, " + printLabelDetails.barcodeHtml}
            alt="Supplier item barcode"
            width="100%"
            height="auto"
          />
          <p className="text-barcode-slip text-center">
            {printLabelDetails.barcode}
          </p>
        </div>
        <div className="align-bottom">
          <p className="internal-use-text">INTERNAL USE ONLY!</p>
          <p className="text-barcode-slip">Designed by: EGETECH Inc.</p>
        </div>
      </div>
    </div>
  );
}
