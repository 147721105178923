import { useEffect, useRef, useState } from "react";
import { InventoryManufacturedMaterialViewModel } from "../../../viewmodels/inventory/manufacturedMaterial/InventoryManufacturedMaterialViewModel";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { formatDate, withRouter } from "../../../shared/utility";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { InputText } from "primereact/inputtext";
import EmptyModal from "../../../components/modals/EmptyModal";
import SimpleBanner from "../../../components/SimpleBanner";

import { Paginator } from "primereact/paginator";

function InventoryManufacturedMaterialScreen(props) {
  const { logout } = props;
  const {
    getInStockRawMaterial,
    loading,
    products,
    onManufacturedInventoryAdjusted,
    showErrorModal,
    emptyModal,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
  } = InventoryManufacturedMaterialViewModel(logout);

  var currentPage = 1;
  useEffect(() => {
    getInStockRawMaterial(null, currentPage);
  }, []);

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(20);

  const searchRef = useRef();

  const handleSearch = () => {
    getInStockRawMaterial(searchRef.current.value, currentPage);
  };

  const handlePagination = (event) => {
    currentPage = event.page + 1;
    setFirst(event.first);
    setRows(event.rows);
    getInStockRawMaterial(searchRef.current.value, currentPage);
  };

  const [expandedRows, setExpandedRows] = useState(null);

  const allowExpansion = (rowData) => {
    return true;
  };

  const packingSizeParentTemplate = (data) => {
    return (
      <div>
        {data.packing_size} {data.unit_of_measure}
      </div>
    );
  };

  const amountInStockParentTemplate = (data) => {
    return (
      <div>
        {data.stock_in_weight.toFixed(2)} {data.unit_of_measure}
      </div>
    );
  };

  const totalDollarAmountParentTemplate = (data) => {
    return <div>${data.itemDollarAmount}</div>;
  };

  const totalDollarAmountSubItemTemplate = (data) => {
    return <div>${data.total_dollar_amount}</div>;
  };

  const packagingDateSubItemTemplate = (data) => {
    return formatDate(data.packaging_date);
  };

  const unitPriceAmountSubItemTemplate = (data) => {
    return <div>${data.price}</div>;
  };

  const quantityInStockParentTemplate = (data) => {
    return <div>{data.total_individual_units}</div>;
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="number"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
      />
    );
  };

  const onCellEditComplete = (e) => {
    let { rowData, rowIndex, newValue, field, originalEvent: event } = e;

    if (rowData.is_demo === 1) {
      switch (field) {
        default:
          if (newValue.trim().length > 0) {
            onManufacturedInventoryAdjusted(rowData, rowIndex, newValue);
            rowData[field] = newValue;
          } else {
            event.preventDefault();
          }
          break;
      }
    } else {
      showErrorModal(
        "This is an actual packaged item, please go to packages to edit amount."
      );
    }
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        {/* <h5>Orders for {data.name}</h5> */}
        <DataTable
          value={data.subItems}
          tableClassName="min-w-full bg-white divide-y divide-gray-300"
          cellClassName="py-2 bg-gray-100 border-1-bottom-gray px-2 text-sm"
          editMode="cell"
        >
          <Column field="product_lot" header="Product Lot" />
          <Column body={packagingDateSubItemTemplate} header="Packaging Date" />
          <Column
            field="total_individual_units"
            header="Total Individual Units"
            style={{ textAlign: "right" }}
            editor={(options) => textEditor(options)}
            onCellEditComplete={onCellEditComplete}
          />
          <Column
            body={unitPriceAmountSubItemTemplate}
            header="Unit price"
            style={{ textAlign: "right" }}
          />
          <Column
            body={totalDollarAmountSubItemTemplate}
            header="Total Dollar Amount"
            style={{ textAlign: "right" }}
          />
        </DataTable>
      </div>
    );
  };

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center px-4 py-5 sm:px-4 card-custom">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                In stock Manufactured Products
              </h1>
              <div className="mt-5 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchReceivingInvoice"
                    id="searchReceivingInvoice"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by product name"
                    ref={searchRef}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSearch() : null
                    }
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              {/* <button
                type="button"
                className="block rounded-md bg-main-purple px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add user
              </button> */}
              <h1 className="text-base font-bold leading-6 text-red-500">
                Total Manufactured In Stock{" "}
                <span className="text-green-700">
                  ${products.totalStockDollarAmount}
                </span>
              </h1>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden card-custom">
                    <DataTable
                      value={products.data}
                      expandedRows={expandedRows}
                      onRowToggle={(e) => {
                        setExpandedRows(e.data);
                      }}
                      rowExpansionTemplate={rowExpansionTemplate}
                      dataKey="id"
                      tableClassName="min-w-full bg-white divide-y divide-gray-300 text-sm manufactured-inventory-table-parent"
                      cellClassName="p-2 bg-white border-1-bottom-gray text-sm"
                    >
                      <Column field="product_code" header="PLU" />
                      <Column field="product_name" header="Product Name" />
                      <Column field="description_en" header="Description" />
                      <Column
                        header="Case weight"
                        body={packingSizeParentTemplate}
                        style={{ textAlign: "right" }}
                      />
                      <Column
                        header="Quantity in stock by weight"
                        body={amountInStockParentTemplate}
                        style={{ textAlign: "right" }}
                      />
                      <Column
                        header="Quantity in stock/case"
                        body={quantityInStockParentTemplate}
                        style={{ textAlign: "right" }}
                      />
                      <Column
                        header="Total Dollar Amount"
                        body={totalDollarAmountParentTemplate}
                        style={{ textAlign: "right" }}
                      />

                      <Column
                        expander={allowExpansion}
                        style={{ width: "5rem" }}
                      />
                    </DataTable>
                  </div>
                )}
              </div>
              <div className="w-full">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={products.total}
                  // rowsPerPageOptions={[10, 20, 30]}
                  onPageChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InventoryManufacturedMaterialScreen)
);
