import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";
import { updateObjectInArray } from "../../../shared/utility";

export function InventoryRawMaterialViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({ data: [] });
  const [productTypeOptions, setProductTypeOptions] = useState([]);

  const getInStockRawMaterial = async function (search, page, product_type) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
      product_type: product_type,
    };
    let callResult = await makePostApiCall("getInStockRawMaterial", payload);

    if (callResult.success) {
      setProducts(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  const getInStockRawMaterialByBomPlu = async function (id) {
    let callResult = await makePostApiCall(
      `getInStockRawMaterialByBomPlu/${id}`
    );

    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onUpdateReceivingItemStock = async function (
    id,
    newInventory,
    oldInventory,
    parentIndex,
    oldItemCost,
    newItemCost
  ) {
    let callResult = await makePostApiCall(`updateReceivingItemStock/${id}`, {
      newInventory: newInventory,
      newItemCost: newItemCost,
    });

    if (callResult.success) {
      const currentProducts = [...products.data];
      const newItem = oldInventory - newInventory;
      const newTotalStock =
        parseFloat(currentProducts[parentIndex].total_stock) - newItem;
      const newTotalCost = (
        parseFloat(currentProducts[parentIndex].total_cost.replace(",", "")) -
        parseFloat(oldItemCost.replace(",", "")) +
        parseFloat(newItemCost)
      ).toFixed(2);

      const parentItemBeingEdited = {
        ...currentProducts[parentIndex],
        total_stock: newTotalStock.toFixed(2),
        old_in_stock: newTotalStock,
        total_cost: newTotalCost,
      };

      var payload = {
        index: parentIndex,
        item: parentItemBeingEdited,
      };
      const newPackagingItems = updateObjectInArray(currentProducts, payload);
      setProducts({ ...products, data: newPackagingItems });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getProductTypes = async function () {
    let callResult = await makePostApiCall(`productTypesForDropdown`);

    if (callResult.success) {
      setProductTypeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    getInStockRawMaterial,
    getInStockRawMaterialByBomPlu,
    loading,
    products,
    onUpdateReceivingItemStock,
    getProductTypes,
    productTypeOptions,
  };
}
