import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";
import { BannerNotificationType } from "../../../components/SimpleBanner";
import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";
import AddInventoryForm from "../../../components/forms/setup/product/AddInventoryForm";
import ImportMediaCsvDropzone from "../../../components/importMedia/ImportMediaCsvDropzone";

export function ProductsViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({
    data: [],
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [productsForDropdown, setProductsForDropdown] = useState([]);

  const fetchProductsForDropdown = async function (search, page) {
    let callResult = await makePostApiCall(`fetchProductsForDropdown`);
    if (callResult.success) {
      setProductsForDropdown(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getProducts = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`products`, payload);
    setLoading(false);
    if (callResult.success) {
      setProducts(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteProduct = async function (id, index) {
    setLoading(true);

    let callResult = await makePostApiCall(`deleteProduct/${id}`, {});
    setLoading(false);
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getProducts(null, 0);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteProduct = function (product, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteProduct(product.id, index)}
          message={`Are you sure you want to delete ${product.name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const uploadProductsCsv = async function (uploadedFile) {
    const { name, value } = uploadedFile.target;
    const payload = { [name]: value };
    let callResult = await makePostApiCall(
      "uploadProductsCsv",
      payload,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        open: true,
        message: null,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getProducts("", 0);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const addInventoryApiCall = async function (values) {
    let callResult = await makePostApiCall(
      "addManufacturedProductInventory",
      values
    );

    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openImportProduct = () => {
    setEmptyModelOpen({
      childComponent: (
        <ImportMediaCsvDropzone
          fileName="productsCsv"
          actionCall={uploadProductsCsv}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const addInventory = () => {
    setSlideOver({
      childComponent: (
        <AddInventoryForm
          actionCall={addInventoryApiCall}
          productOptions={productsForDropdown}
          setOpen={setSlideOver}
        />
      ),
      open: true,
    });
  };

  return {
    askDeleteProduct,
    emptyModal,
    setEmptyModelOpen,
    getProducts,
    loading,
    products,
    setSlideOver,
    slideOver,
    setShowSimpleNotification,
    showSimpleNotification,
    openImportProduct,
    addInventory,
    fetchProductsForDropdown,
  };
}
